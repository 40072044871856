import { useState, useEffect, useRef } from "react";
import { Container, Form, Button, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";
import { useForm, Controller } from "react-hook-form";

import Divider from '@mui/material/Divider';

import { fetchQuestionText } from "../Features/AI/Actions/AIActions";
import { customStyles } from "../styles/StyledComponents";
import "../styles/Form.css";

const animatedComponents = makeAnimated();

export default function FormComponent({
  title,
  inputs,
  multipleSelects,
  multipleValues,
  selects,
  button,
  logo,
  logoClass,
  action,
  isFormVisible,
  setFormVisible,
  isLoading,
}) {
  const [newInputs, setInputs] = useState(inputs);
  const [newSelects, setSelects] = useState(selects);
  const [newMultiSelects, setMultiSelects] = useState(multipleSelects);
  const [newMultiValues, setMultiValues] = useState(multipleValues);
  const [error, setError] = useState(false);
  const [validated, setValidated] = useState(false);
  const mounted = useRef(null);

  const { register, control } = useForm();

  const components = {
    DropdownIndicator: null,
  };

  const handleAIcall = () => { fetchQuestionText() }

  const handleChange = (value, actionMeta) => {
    setError(false);
    setMultiValues((curr) => {
      return { ...curr, state: { inputValue: "", value } };
    });
  };
  const handleInputChange = (inputValue) => {
    setMultiValues((curr) => {
      return { ...curr, state: { ...curr.state, inputValue } };
    });
  };

  const handleKeyDown = (event) => {
    setError(false);
    const { state } = newMultiValues;
    const { inputValue, value } = state;
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (/[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/.test(inputValue)) {
          setMultiValues((curr) => {
            return {
              ...curr,
              state: {
                inputValue: "",
                value: [...value, createOption(inputValue)],
              },
            };
          });
        } else {
          setError(true);
        }
        event.preventDefault();
        break;
      default:
    }
  };

  const createOption = (label) => ({
    label,
    value: label,
  });

  const handleSendInvitation = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      const { state } = newMultiValues;
      const { value } = state;

      if (value.length > 0) {
        value.map((email) => {
          console.log(`send email to ${email.value}`);
          return null;
        });
      } else {
        setError(true);
      }
    }
    setFormVisible(false);
    setValidated(true);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity()) {
      let data = {};

      if (newInputs.length) {
        newInputs.forEach((input) => {
          if (input.type === "number") {
            data[input.id] = parseInt(input.value);
          } else {
            data[input.id] = input.value;
          }
        });
      }
      if (newSelects.length) {
        newSelects.forEach((select) => {
          data[select.id] = parseInt(select.value);
        });
      }
      if (newMultiSelects.length) {
        newMultiSelects.forEach((select) => {
          data[select.id] = select.value;
        });
      }
      action(data);
      setInputs(inputs);
      setSelects(selects);
      setMultiSelects(multipleSelects);
      setMultiValues(multipleValues);
    }
    setValidated(true);
  };

  useEffect(() => {
    mounted.current = true;
    if (isLoading) {
      return;
    }
    if (mounted.current) {
      setSelects(selects);
      setMultiSelects(multipleSelects);
      setMultiValues(multipleValues);
    }

    return () => (mounted.current = false);
  }, [isLoading, multipleSelects, multipleValues, selects]);

  return (
    <>
      {logo && logoClass.includes("position-fixed") && (
        <Container className="text-end">
          <img
            className={logoClass}
            src={logo}
            alt="Icon"
            onClick={() => setFormVisible(true)}
            style={{
              opacity: isLoading ? 0.5 : 1,
              cursor: isLoading ? "wait" : "pointer",
              pointerEvents: "all",
            }}
          />
        </Container>
      )}
      {logo && logoClass.includes("position-absolute") && (
        <img
          className={logoClass}
          src={logo}
          alt="Icon"
          onClick={() => setFormVisible(true)}
        />
      )}
      {!isLoading && (
        <Offcanvas
          show={isFormVisible}
          onHide={() => setFormVisible(false)}
          placement="end"
          className="background-secondary"
          aria-label={`${title} Form`}
        >
          <Offcanvas.Header className="mb-2" closeButton>
            <Offcanvas.Title id="Offcanvas-styling-title">
              <p className="text-theme fw-bold m-0 h4">{title}</p>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Divider />
          <Offcanvas.Body>
            <Form
              id="form"
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              className="d-flex flex-column flex-nowrap"
            >
              {newInputs.map((input, i) => {
                return (
                  <Form.Group
                    className={
                      input.order ? `mb-3 order-${input.order}` : "mb-3"
                    }
                    key={input.id}
                    controlId={input.id}
                  >
                    <Form.Label>{input.label}</Form.Label>
                    {input.type === "textarea" ? (
                      <>
                        <Form.Control
                        required={input.required}
                        as="textarea"
                        rows={3}
                        value={newInputs[i].value}
                        onChange={(e) =>
                          setInputs((arr) => {
                            const newArr = [...arr];
                            newArr[i].value = e.target.value;
                            return newArr;
                          })
                        }
                      />
                      <Button 
                        variant="dark" 
                        type="submit"
                        onClick={handleAIcall}
                      >
                       Generate Text
                      </Button>
                      </>
                    ) : input.id === "question" ? (
                      ["4", "9", "10", "11"].includes(
                        newSelects.map((select) => {
                          return select.value;
                        })[0]
                      ) ? (
                        <>
                          <Form.Control
                            required={input.required}
                            as="textarea"
                            rows={3}
                            value={newInputs[i].value}
                            onChange={(e) =>
                              setInputs((arr) => {
                                const newArr = [...arr];
                                newArr[i].value = e.target.value;
                                return newArr;
                              })
                            }
                            pattern=".*\{.*\}.*"
                          />

                          <Form.Text
                            id="passwordHelpBlock"
                            muted
                            style={{ whiteSpace: "pre-wrap" }}
                          >
                            {input.message}
                          </Form.Text>
                          <Form.Control.Feedback type="invalid">
                            This question type needs at least one blank.
                          </Form.Control.Feedback>
                        </>
                      ) : (
                        <Form.Control
                          required={input.required}
                          as="textarea"
                          rows={3}
                          value={newInputs[i].value}
                          onChange={(e) =>
                            setInputs((arr) => {
                              const newArr = [...arr];
                              newArr[i].value = e.target.value;
                              return newArr;
                            })
                          }
                        />
                      )
                    ) : (
                      <Form.Control
                        required={input.required}
                        type={input.type}
                        min={input.type === "number" ? 0 : null}
                        rows={3}
                        value={newInputs[i].value}
                        onChange={(e) =>
                          setInputs((arr) => {
                            const newArr = [...arr];
                            newArr[i].value = e.target.value;
                            return newArr;
                          })
                        }
                      />
                    )}
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    {input.id === "invitation" && (
                      <div className="my-5 text-end">
                        <Button variant="dark" type="submit">
                          Send
                        </Button>
                      </div>
                    )}
                  </Form.Group>
                );
              })}
              {newMultiSelects.map((select, i) => {
                return (
                  <Form.Group
                    className={
                      select.order ? `mb-3 order-${select.order}` : "mb-3"
                    }
                    key={select.id}
                    controlId={select.id}
                  >
                    <Form.Label>{select.label}</Form.Label>
                    <Select
                      isClearable
                      required
                      styles={customStyles}
                      closeMenuOnSelect={false}
                      components={animatedComponents}
                      isMulti
                      options={select.options}
                      value={newMultiSelects[i].value}
                      setValue={select.setValue}
                      onChange={(e) => {
                        setMultiSelects((arr) => {
                          const newArr = [...arr];
                          newArr[i].value = e;
                          return newArr;
                        });
                      }}
                    />
                  </Form.Group>
                );
              })}
              {newSelects.map((select, i) => {
                return (
                  <Form.Group
                    className={
                      select.order ? `mb-3 order-${select.order}` : "mb-3"
                    }
                    key={select.id}
                    controlId={select.id}
                  >
                    <Form.Label>{select.label}</Form.Label>
                    <Form.Select
                      required
                      aria-label="Default select"
                      value={newSelects[i].value}
                      onChange={(e) =>
                        setSelects((arr) => {
                          const newArr = [...arr];
                          newArr[i].value = e.target.value;
                          return newArr;
                        })
                      }
                    >
                      <option hidden>
                        {select.label === "Venue"
                          ? "VICTVS (remote)"
                          : "Select an option"}
                      </option>
                      {select.options.map((option, i) => {
                        return (
                          <option key={i} value={option.value}>
                            {option.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>
                );
              })}
              <div className="my-5 text-end">
                <Button
                  data-cy="formButton"
                  variant="dark"
                  type="submit"
                >
                  {button}
                </Button>
              </div>
            </Form>
            {Object.keys(newMultiValues).length > 0 && (
              <Form
                id="form_candidates"
                onSubmit={handleSendInvitation}
                className="d-flex flex-column flex-nowrap"
                noValidate
                validated={validated}
              >
                <Form.Group
                  className="mb-3 was-validated"
                  key={newMultiValues.id}
                  controlId={newMultiValues.id}
                >
                  <Form.Label>{newMultiValues.label}</Form.Label>
                  <Controller
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { isTouched, isDirty, error },
                      formState,
                    }) => (
                      <CreatableSelect
                        {...register("candidates", {
                          required: true,
                          maxLength: 20,
                        })}
                        styles={customStyles}
                        components={components}
                        inputValue={newMultiValues.state.inputValue}
                        isClearable
                        isMulti
                        menuIsOpen={false}
                        onChange={handleChange}
                        onInputChange={handleInputChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Enter the emails..."
                        value={newMultiValues.state.value}
                        isLoading={isLoading}
                      />
                    )}
                    control={control}
                    name="candidate"
                    rules={{
                      required: true,
                      pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                    }}
                  />
                  {error && (
                    <div className="mt-2 error">
                      Enter a valid email address
                    </div>
                  )}
                </Form.Group>
                <div className="my-5 text-end">
                  <Button variant="dark" type="submit">
                    Send invitation
                  </Button>
                </div>
              </Form>
            )}
          </Offcanvas.Body>
        </Offcanvas>
      )}
    </>
  );
}
