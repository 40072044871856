import axios from "axios";

const ORIGINAL_BASE_URL = "https://twvictvs.victvs.co.uk/vx/api/v0/vx";
const ORIGINAL_BASE_ADMIN_URL = "https://twvictvs.victvs.co.uk/vx/adminapi/v0/vxadmin";

const BASE_URL = "https://vxadmin.victvsone.com/api/v0/vx/";
const BASE_ADMIN_URL = "https://vxadmin.victvsone.com/adminapi/v0/vxadmin/";

const getUserRoles = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const roles = user?.roles;
  return roles;
};

const getTimeZone = () => {
  const date = new Date();
  const offset = date.getTimezoneOffset();
  const minutes = offset % 60;
  const hours = Math.floor(offset / 60);

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
};

const getLocalLanguage = () => {
  const userLocale =
    navigator.languages && navigator.languages.length
      ? navigator.languages[0]
      : navigator.language;

  const language = userLocale.split("-")[0];
  return language;
};

export const getURL = (video = false) => {
  const roles = getUserRoles();
  const language = getLocalLanguage();

  const Axios = axios.create({
    baseURL: roles?.includes(1) ? ORIGINAL_BASE_ADMIN_URL : ORIGINAL_BASE_URL,
  });

  Axios.defaults.headers.common["Content-Type"] = video
    ? "multipart/form-data"
    : "application/json";
  Axios.defaults.headers.common["X-Custom-Language"] = language;
  // Axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
  // Axios.defaults.headers.common["X-Custom-Timezone"] = timezone;
  Axios.defaults.headers.common["Authorization"] =
    "Bearer 678e22bdb7d12c297cba77089950d84d";
  return Axios;
};

export const getBaseURL = (video = false) => {
  const language = getLocalLanguage();
  const Axios = axios.create({ baseURL: ORIGINAL_BASE_URL });
  
  Axios.defaults.headers.common["Content-Type"] = "multipart/form-data; boundary=form-data-ApKiywFYpCtdpqEo"
  Axios.defaults.headers.common["X-Custom-Language"] = language;
  Axios.defaults.headers.common["Authorization"] = "Bearer 678e22bdb7d12c297cba77089950d84d";

  return Axios;
};

export const setHeadersAndParameters = (params, signal = false, video = false) => {
  const accessToken = sessionStorage.getItem("auth");
  const roles = getUserRoles();
  const timezone = getTimeZone();
  const language = getLocalLanguage();
  const apiKey = roles?.includes(1) ?
    "d194J8HprfVQ63u" : 
    "678e22bdb7d12c297cba77089950d84d";
  const headersWithParams = {
    headers: {
      "Content-Type": video ? "multipart/form-data" : "application/json",
      "X-Custom-Format": "html",
      "X-Custom-Language": language,
      "X-Custom-Timezone": timezone,
      Authorization: `Bearer ${apiKey}.${accessToken}`,
    },
    params: params.params,
    signal: signal
  };

  return headersWithParams;
};

export const setImageHeaders = () => {
  const accessToken = sessionStorage.getItem("auth");
  const timezone = getTimeZone();
  const language = getLocalLanguage();
  const apiKey = "678e22bdb7d12c297cba77089950d84d";

  const headers = {
    headers: {
      "X-Custom-Format": "html",
      "X-Custom-Language": language,
      "X-Custom-Timezone": timezone,
      Authorization: `Bearer ${apiKey}.${accessToken}`,
    },
  };

  return headers;
};

export const setHeaders = (video = false) => {
  const accessToken = sessionStorage.getItem("auth");
  const roles = getUserRoles();
  const timezone = getTimeZone();
  const language = getLocalLanguage();
  const apiKey = roles?.includes(1)
    ? "d194J8HprfVQ63u"
    : "678e22bdb7d12c297cba77089950d84d";
  const headers = {
    headers: {
      "Content-Type": video ? "multipart/form-data" : "application/json",
      "X-Custom-Format": "html",
      "X-Custom-Language": language,
      "X-Custom-Timezone": timezone,
      // "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${apiKey}.${accessToken}`,
    },
  };
  return headers;
};