/* REACT PACKAGE IMPORTS */
import {useCallback, useEffect, useState} from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

/* INTERNAL IMPORTS */
// Components
import NavBar from "./NavBar";
import IdleTimeOutModal from "./IdleTimeOutModal";

import { LogOutAuthAction } from "../Features/Auth/Actions/AuthActions";

// Images
import logoLogout from "../assets/logout.svg";
import logoUser from "../assets/user.svg";


import useWebSocket, { ReadyState } from "react-use-websocket";

var wsToken = sessionStorage.getItem("auth");
console.log(wsToken, "this is the User Auth Token");
var wsAPIKey = '524d45d4279ae5033fecb52561c80c64';


const Layout = ({ tabs, logo, isEmbedded }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(LogOutAuthAction())
      .then(() => {
        navigate("/login", { replace: true });
        if (document.fullscreenElement) document.exitFullscreen();
        window.location.reload(true);
        return false;
      });
  };

    const [socketUrl, setSocketUrl] = useState('wss://vxadmin.victvsone.com/wss2/');
    const [messageHistory, setMessageHistory] = useState([]);
    const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
        onOpen: () => console.log("Connection Opened"),
        onMessage: message=> console.log(message?.data),
        onClose: () => console.log("Connection Closed"),
    });

    const sendMessageToWS = useCallback((message) => sendMessage(message), []);

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory((prev) => prev.concat(lastMessage));
        }
    }, [lastMessage]);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    const sendWSMessage = (message) => {
        console.log("Message sending")
        const stringMsg = JSON.stringify(message);
        sendMessageToWS(stringMsg);
    };

    console.log("this is the connection status >>", connectionStatus);

  return (
    <main className="App">
      {/* shows inactivity */}
      <IdleTimeOutModal handleLogout={handleLogout} />
      {
        !isEmbedded &&
        <NavBar
          tabs={tabs}
          logo={logo}
          logoLogout={logoLogout}
          logout={handleLogout}
          logoUser={logoUser}
        />
      }
      <Outlet />
        {/*<button onClick={() => sendWSMessage({action: "register", data: {token: wsToken, apikey: wsAPIKey}})}>Open</button>*/}
        {/*<button onClick={() => sendWSMessage({action: "sendmessage", data: {message: 'wsTestText'}})}>Send Message</button>*/}
    </main>
  );
};

export default Layout;
