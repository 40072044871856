/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import { useSelector, useDispatch } from "react-redux";

/* MUI PACKAGE IMPORTS */
import SortIcon from "@mui/icons-material/Sort";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Tooltip from "@mui/material/Tooltip";

/* INTERNAL IMPORTS */
import {
  updateSection,
  adjustOrder,
} from "../../Features/Assessments/Actions/AssessmentsActions";
import { fetchQuestionHeader } from "../../Features/Questions/Actions/QuestionActions";
import ViewQuestion from "../Questions/ViewQuestion";


export default function QuestionsList({
  section,
  questionTotal,
  setShowQuestion,
  showQuestion,
  setQuestionHeaderIdShow,
  questionHeaderIdShow,
  setQuestionHeaderId,
  setSectionNum,
  setShowModal,
  setMsg,
  setButton,
  setAction,
}) {
  const { questions } = useSelector(store => store.assessments);
  const { questionHeaders } = useSelector(store => store.questions);

  const [state, setState] = useState([]);
  const [userAnswerArray, setUserAnswerArray] = useState([]);
  const [esid] = useState(section.esid);

  const mounted = useRef(true);
  const dispatch = useDispatch();

  // Drag and Drop Handler
  const onDragDropEnds = ({ oldIndex, newIndex }) => {
    const qhid = questions
      .filter(questions => questions.sectionid === esid)[0]
      .questions.slice(0)
      .reverse()[oldIndex].qhid;
    const adjust_order = oldIndex - newIndex;
    dispatch(adjustOrder({ esid, qhid, adjust_order }));
  };

  const handleRandom = (e) => {
    const value = e.target.checked;
    const sectionState = { qhrandom : value };
    console.log(sectionState);
    dispatch(updateSection({ esid, sectionState }));
  };

  useEffect(() => {
    mounted.current = true;
    if (questions.length === 0) return;
    if (mounted.current) {
      setState(() => {
        return questions
          .filter(questions => questions.sectionid === section.esid)[0]
          .questions.slice(0)
          .map((question) => {
            return {
              admintitle: question.admintitle,
              id: question.qhid,
              questions: question.questions,
            };
          });
      });
    }

    return () => mounted.current = false;
  }, [questions, dispatch, section]);

  return (
    <div>
      <div className="mb-4">
        <h2 className="fw-bold">Section Questions</h2>
        <p className="m-0 text-muted mb-2">Total questions in section: {questionTotal}</p>

        <Form id="formQuestionList">
          <Form.Group className="d-flex align-items-center">
            <Form.Check
              type="checkbox"
              aria-label="shuffle question order"
              name="singleAnswer"
              checked={section.qhrandom}
              onChange={handleRandom}
            />
            <Form.Label className="my-1 mx-2" >Would you like to display the questions in this section in a shuffled order?</Form.Label>
          </Form.Group>
        </Form>
      </div>
      <hr />
      <h3 className="fw-bold fs-5">Questions Preview</h3>
      <p className="mb-3 text-muted">Questions within this section, drag and drop to re-order the question list</p>
      <ul className="list-group flex-fill">
        <ReactSortable
          list={state}
          setList={setState}
          group="groupName"
          animation={200}
          easing={"cubic-bezier(1, 0, 0, 1)"}
          delayOnTouchStart={true}
          delay={2}
          dragClass="sortable-drag"
          onEnd={onDragDropEnds}
        >
          {
            state.length > 0 &&
            state.map((item, i) => (
              <div
                key={i}
                className="font-secondary my-3 px-2 py-3 sortable-list-item flex-fill border rounded"
              >
                <div className="d-flex justify-content-between">
                  <div className="d-flex align-items-center">
                    <SortIcon
                      className="mx-2"
                      fontSize="small"
                      sx={{ color: "black" }}
                    />
                    <p className="m-0">{item.admintitle} </p></div>
                  <div>

                    <Tooltip
                      title={
                        showQuestion && item.id === questionHeaderIdShow ?
                          "Hide question" :
                          "View question"
                      }
                    >
                      <IconButton
                        aria-label="View question"
                        onClick={() => {
                          dispatch(fetchQuestionHeader(item.id));
                          setQuestionHeaderIdShow(item.id);
                          setShowQuestion(currShow => !currShow);
                        }}
                      >
                        {
                          showQuestion &&
                            item.id === questionHeaderIdShow ?
                            <VisibilityOffIcon fontSize="small" /> :
                            <VisibilityIcon fontSize="small" />
                        }
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Open question">
                      <IconButton
                        aria-label="Open question"
                        onClick={() => window.open(`/admin/question_bank/${item.id}`)}
                      >
                        <OpenInNewIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Remove question">
                      <IconButton
                        aria-label="Remove question"
                        onClick={() => {
                          setQuestionHeaderId(item.id);
                          setSectionNum(section.esid);
                          setShowModal(true);
                          setMsg(
                            `Are you sure you want remove the question "${item.admintitle}" from the section?`
                          );
                          setButton("Delete");
                          setAction(1);
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </div>


                  {
                    !item.questions &&
                    <span className="error">
                      <ErrorOutlineIcon fontSize="small" />
                      You need to add some questions to this header. Click on
                      the button below to edit it properly.
                    </span>
                  }

                </div>
                {questionHeaders.map((questionHeader, i) => {
                  return (
                    showQuestion &&
                    questionHeader.qhid === item.id &&
                    questionHeader.qhid === questionHeaderIdShow &&
                    <div className="m-2" key={i}>
                      <ViewQuestion
                        questions={questionHeader.questions || []}
                        questionHeader={questionHeader}
                        userAnswerArray={userAnswerArray}
                        setUserAnswerArray={setUserAnswerArray}
                      />
                    </div>
                  );
                })}
              </div>
            ))}
        </ReactSortable>
      </ul>
    </div>
  );
}
