/* REACT PACKAGE IMPORTS */
import { Form } from "react-bootstrap"

import AssistantButton from "./AssistantButton";



export default function TextAreaField({ textArea, handleChange }) {
  const fieldLabel = (textArea.isRequired) ?
    <Form.Label className="fw-bold m-0">{textArea.label}
      <span className="asterisk-red"> *</span>
    </Form.Label> :
    <Form.Label className="fw-bold m-0">{textArea.label}</Form.Label>;


  return (
    <Form.Group
      className={"mb-4"}
      key={textArea.id}
      controlId={textArea.id}
    >
      <div className="d-flex flex-column">
        {fieldLabel}
        {
          textArea.message &&
          <Form.Text className="text-muted mt-0 mb-1">{textArea.message}</Form.Text>
        }
      </div>

      <Form.Control
        data-cy={textArea.label}
        type={textArea.type}
        as="textarea"
        rows={textArea.rows}
        required={textArea.isRequired}
        defaultValue={textArea.value}
        onChange={handleChange}
        name={textArea.id}
      />

      {/* <AssistantButton /> */}
      
      {
        textArea.invalidFeedback &&
        <Form.Control.Feedback type="invalid">
          {textArea.invalidFeedback}
        </Form.Control.Feedback>
      }
      {
        textArea.validFeedback &&
        <Form.Control.Feedback type="valid">
          {textArea.validFeeback}
        </Form.Control.Feedback>
      }
    </Form.Group>
  )
};