import axios from "axios";

const OPEN_AI_API_KEY = "sk-proj-TrxeE3A86B6bBmED8usrT3BlbkFJmV1xtc4nfJ18AwSEfMc1";
const OPEN_AI_URL = "https://api.openai.com/v1";


const fetchQuestionText = 
  async () => {
    try {
      const Axios = axios.create({ baseURL: OPEN_AI_URL });
      const systemMessage = {
        "role": "system",
        "content": "You are a helpful assistant designed to output JSON.",
      };
      const messageOne = {
        "role": "user", 
        "content": "generate three suggestions for interesting question topics in an array called topic_suggestions. The output must be in JSON format with each topic containing a unique topic id, topic name, example question and answer"
      };
      const requestBody = {
        "messages": [systemMessage, messageOne],
        "model":"gpt-4o",
        "max_tokens": 700,
        "temperature": 0.7,
        "response_format": { type: "json_object" },
        // "seed": 12345
      };
      const headers = {
        headers: { 
          "Authorization": `Bearer sk-proj-bOB6kmjwX9UNVUsoEHmlT3BlbkFJzIa9DPAeLrxLzR4APBlb`,
          "Content-Type": "application/json"
        }
      };

      const response = await Axios.post("/chat/completions", requestBody,headers);
      console.log(response.data.choices[0].message.content);
      return response;
    } catch (error) {
      console.log(error, "this is the error");
    }
};

export {
  fetchQuestionText,
};